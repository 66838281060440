import _export from "../internals/export";
import _arrayIteration from "../internals/array-iteration";
import _arrayMethodHasSpeciesSupport from "../internals/array-method-has-species-support";
var $ = _export;
var $map = _arrayIteration.map;
var arrayMethodHasSpeciesSupport = _arrayMethodHasSpeciesSupport;
var HAS_SPECIES_SUPPORT = arrayMethodHasSpeciesSupport("map"); // `Array.prototype.map` method
// https://tc39.es/ecma262/#sec-array.prototype.map
// with adding support of @@species

$({
  target: "Array",
  proto: true,
  forced: !HAS_SPECIES_SUPPORT
}, {
  map: function map(callbackfn
  /* , thisArg */
  ) {
    return $map(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
  }
});
export default {};